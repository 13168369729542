import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardFooter, Input, Typography, Alert, Switch } from "@material-tailwind/react";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
axios.defaults.withCredentials = true;


const PHONE_NUMBER_REGEX = /(?!^$)([^\s])/;


const WingipayPayment = (props) => {
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.userId.value);
    const totalDeposits = useSelector((state) => state.totalDeposits.value);
    const phone = useSelector((state) => state.phone.value);

 


    const transactionPhoneRef = useRef();
    const transactionAmountRef = useRef();
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState([]);

    const [rolloverAmount, setRolloverAmount] = useState(0);
    const [bonusAmount, setBonusAmount] = useState(0);
    const [totalBonusAmount, setTotalBonusAmount] = useState(0);
    const [bonusPercentage, setBonusPercentage] = useState(0);


    const [transactionPhone, setTransactionPhone] = useState(phone);
    const [validTransactionPhone, setValidTransactionPhone] = useState(false);
    const [transactionPhoneFocus, setTransactionPhoneFocus] = useState(false);

    const [transactionAmount, setTransactionAmount] = useState('');
    const [validTransactionAmount, setValidTransactionAmount] = useState(false);
    const [transactionAmountFocus, setTransactionAmountFocus] = useState(false);

    const [wingipayPaymentConfirmation, setWingipayPaymentConfirmation] = useState(false);

    const [bonusSwitch, setBonusSwitch] = useState(true);

    const bonusMinimumCashoutCoefficient = JSON.parse(localStorage.getItem('bonusMinimumCashoutCoefficient'));
    const bonusMinimumCashoutCoefficientValue = JSON.parse(localStorage.getItem('bonusMinimumCashoutCoefficientValue'));
    const depositBonusLowerLimit = JSON.parse(localStorage.getItem('depositBonusLowerLimit'));
    const depositBonusUpperLimit = JSON.parse(localStorage.getItem('depositBonusUpperLimit'));
    const firstDepositBonusRolloverMultiplier = JSON.parse(localStorage.getItem('firstDepositBonusRolloverMultiplier'));
    const depositBonusRolloverMultiplier = JSON.parse(localStorage.getItem('depositBonusRolloverMultiplier'));
    const defaultDeposit = JSON.parse(localStorage.getItem('defaultDeposit'));
    const firstDepositBonusMultiplier = JSON.parse(localStorage.getItem('firstDepositBonusMultiplier'));
    const depositBonusMultiplier = JSON.parse(localStorage.getItem('depositBonusMultiplier'));


    const minimumDeposit = JSON.parse(localStorage.getItem('minimumDeposit'));
    const maximumDeposit = JSON.parse(localStorage.getItem('maximumDeposit'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));
    const directDeposit = JSON.parse(localStorage.getItem('directDeposit'));
    const directDepositInstructions = JSON.parse(localStorage.getItem('directDepositInstructions'));
    const withdrawalInstructions = JSON.parse(localStorage.getItem('withdrawalInstructions'));
    const mpesaPaymentInstructions = JSON.parse(localStorage.getItem('mpesaPaymentInstructions'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));

    const countryCode = JSON.parse(localStorage.getItem('countryCode'));
    const firstDepositBonus = JSON.parse(localStorage.getItem('firstDepositBonus'));
    const depositBonus = JSON.parse(localStorage.getItem('depositBonus'));
   // const wingipayPaymentInstructions = JSON.parse(localStorage.getItem('wingipayPaymentInstructions'));

    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
      }

      const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    const handleBonusSwitch = () => {
        if (bonusSwitch === false) {
            setBonusSwitch(() => true);
        } else {
            setBonusSwitch(() => false);
        }
    };
    // useEffect(() => {
    //     transactionPhoneRef.current.focus();
    // }, [])

    // useEffect(() => {
    //     transactionAmountRef.current.focus();
    // }, [])

    // useEffect(() => {
    //     setValidTransactionPhone(PHONE_NUMBER_REGEX.test(transactionPhone));
    // }, [transactionPhone])

    useEffect(() => {
        if (transactionAmount >= Number(minimumDeposit) && transactionAmount <= Number(maximumDeposit)) {
            setValidTransactionAmount(true);
        } else {
            setValidTransactionAmount(false);
        }
    }, [transactionAmount, minimumDeposit, maximumDeposit])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (directDeposit == true) {
            setTransactionPhone(() => phone);
        }
        props.setWingipayPaymentProcessing(() => true);
        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        axios.post(`${baseUrl}/api/initiateMpesaOnlinePayment`,
            { userId: userId, phone: phone, amount: transactionAmount, offerBonus: bonusSwitch },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
            ).then((response) => { 
               // console.log(response.data.reference);
                props.setWingipayPaymentProcessing(() => false);
                props.setCheckoutRequestID(() => response.data.reference);
                setWingipayPaymentConfirmation(() => true);
                successNotify('Deposit initiated successfully.');
            }).catch((error) => {
                props.setWingipayPaymentProcessing(() => false);
                errorNotify('Transaction Failed');
                setErrMsg('Transaction Failed');
                errRef.current.scrollIntoView();
            });

    }




    return (

        <div>
            {wingipayPaymentConfirmation === false &&
                <div>
                    
                    {directDeposit == false &&
                    <>
                        {/* <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: dusupayPaymentInstructions }} /> */}
                    </>
                    }
                    {directDeposit == true &&
                        <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: directDepositInstructions }} />
                    }

                    <Card className="mx-auto w-full max-w-[24rem] my-5">
                        <CardBody className="pt-0 flex flex-col gap-4">

                            <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                                {errMsg !== '' &&
                                    <li>{errMsg}<br /></li>
                                }

                            </ul>

                            {/* <PhoneInput
                                variant="outlined"
                                label="Phone Number"
                                size="lg"
                                type="text"
                                id="transactionPhone"
                                ref={transactionPhoneRef}
                                country={'gh'}
                                enableSearch={true}
                                autoComplete="off"
                                aria-invalid={validTransactionPhone ? "false" : "true"}
                                aria-describedby="transactionPhoneNote"
                                onChange={(e) => setTransactionPhone(e)}
                                value={transactionPhone}
                                onFocus={() => setTransactionPhoneFocus(true)}
                                onBlur={() => setTransactionPhoneFocus(false)}
                                error={transactionPhone && !validTransactionPhone ? true : false}
                                success={validTransactionPhone ? true : false}
                                required

                            />

                        
                            <Typography
                                id="transactionPhoneNote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal" + transactionPhoneRef && transactionPhone && !validTransactionPhone ? "flex" : "hidden"}
                            >
                                Must be a valid phone number.! <br />
                            </Typography> */}


                            <Input
                                variant="outlined"
                                label="Amount"
                                size="lg"
                                type="number"
                                id="amount"
                                ref={transactionAmountRef}
                                autoComplete="off"
                                aria-invalid={validTransactionAmount ? "false" : "true"}
                                aria-describedby="transactionAmountNote"
                                onChange={(e) => setTransactionAmount(e.target.value)}
                                value={transactionAmount}
                                onFocus={() => setTransactionAmountFocus(true)}
                                onBlur={() => setTransactionAmountFocus(false)}
                                error={transactionAmount && !validTransactionAmount ? true : false}
                                success={validTransactionAmount ? true : false}
                                required
                            />
                            <Typography
                                id="transactionAmountNote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal" + transactionAmountRef && transactionAmount && !validTransactionAmount ? "flex" : "hidden"}
                            >
                                Must be greater than {minimumDeposit}! <br />
                                Must be less than {maximumDeposit}! <br />
                            </Typography>
                            {((firstDepositBonus == true && totalDeposits == 0) || depositBonus == true) &&
                                <Switch
                                    id="bonus-switch"
                                    ripple={true}
                                    //   className="h-full w-full  "
                                    onChange={handleBonusSwitch}
                                    checked={bonusSwitch}
                                    color="green"
                                    label={
                                        <div className="mx-4">
                                            <Typography color="green" className="font-bold  text-[12px]">
                                                Deposit between {depositBonusLowerLimit} & {depositBonusUpperLimit} and get {bonusPercentage}% bonus &#40;{formatAmount(totalBonusAmount)}&#41;
                                            </Typography>

                                            <Typography color="gray" className="font-normal text-[10px]">
                                                <b> Note:</b> With bonus, you will be
                                                {bonusMinimumCashoutCoefficient == true &&
                                                    <>
                                                        &nbsp;limited to a minimum cashout point of
                                                        <b> x{bonusMinimumCashoutCoefficientValue} </b> and
                                                    </>
                                                }
                                                &nbsp;restricted from withdrawing until you place bets totalling to
                                                <b> {formatAmount(rolloverAmount)}</b>.

                                            </Typography>
                                        </div>
                                    }

                                />
                            }

                            {directDeposit == false &&
                                <>
                                    <Input
                                        variant="outlined"
                                        label="Phone Number"
                                        size="lg"
                                        type="text"
                                        id="transactionPhone"
                                        ref={transactionPhoneRef}
                                        autoComplete="off"
                                        aria-invalid={validTransactionPhone ? "false" : "true"}
                                        aria-describedby="transactionPhoneNote"
                                        onChange={(e) => setTransactionPhone(e.target.value)}
                                        value={transactionPhone}
                                        onFocus={() => setTransactionPhoneFocus(true)}
                                        onBlur={() => setTransactionPhoneFocus(false)}
                                        error={transactionPhone && !validTransactionPhone ? true : false}
                                        success={validTransactionPhone ? true : false}
                                        required
                                    />

                                    <Typography
                                        id="transactionPhoneNote"
                                        variant="small"
                                        color="red"
                                        className={"mt-2 flex items-center gap-1 font-normal" + transactionPhoneRef && transactionPhone && !validTransactionPhone ? "flex" : "hidden"}
                                    >
                                        Cannot be empty! <br />
                                    </Typography>
                                </>
                            }

                        </CardBody>
                        <CardFooter className="pt-0">
                            {props.wingipayPaymentProcessing === true &&
                                <Button onClick={handleSubmit} disabled={!validTransactionPhone || !validTransactionAmount || props.wingipayPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </span>
                                </Button>
                            }
                            {props.wingipayPaymentProcessing === false &&
                                <>
                                    {directDeposit == false &&
                                        <Button onClick={handleSubmit} disabled={!validTransactionPhone || !validTransactionAmount || props.wingipayPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                            Submit
                                        </Button>
                                    }
                                    {directDeposit == true &&
                                        <Button onClick={handleSubmit} disabled={!validTransactionAmount || props.wingipayPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                            Submit
                                        </Button>
                                    }
                                </>
                            }
                        </CardFooter>
                    </Card>
                    <div className="grid grid-cols-2 p-2 gap-2 text-sm w-full text-orange-200 border rounded">
                        <span className="w-full">
                            Minimum Deposit: <b>{formatAmount(minimumDeposit)}</b>
                        </span>
                        <span className="w-full">
                            Maximum Deposit: <b>{formatAmount(maximumDeposit)}</b>
                        </span>
                    </div>
                </div>
            }


            {wingipayPaymentConfirmation === true &&
                <div>
                    <Alert icon={<InformationCircleIcon className="h-7 w-7" />} color="amber">
                        After completing the payment on your phone, kindly click the button below to confirm.
                    </Alert>


                    {props.wingipayPaymentProcessing === true &&
                        <Button onClick={props.confirmWingipayPayment} disabled={props.wingipayPaymentProcessing ? true : false} className="my-2 text-xl" color='amber' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }
                    {props.wingipayPaymentProcessing === false &&
                        <Button onClick={props.confirmWingipayPayment} className="my-2 text-xl" color='amber' variant="gradient" fullWidth>
                            Confirm Payment
                        </Button>
                    }
                </div>
            }


        </div>
    )
}

export default WingipayPayment